import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import React from 'react';
import { checkForDefaultLocale } from '@/lib/i18n/checkForDefaultLocale';

const LocaleSelectorPage = dynamic<Record<string, unknown>>(() =>
  import('@/containers/LocaleSelectorPage/LocaleSelectorPage').then(
    mod => mod.LocaleSelectorPage
  )
);

interface Props {
  defaultLocale: boolean;
}

function propsWithDefaultLocale(props: unknown): props is Props {
  return (props as Props).defaultLocale;
}

const Home: NextPage<Props> = props => {
  if (propsWithDefaultLocale(props)) {
    return <LocaleSelectorPage />;
  }

  return null;
};
export default Home;

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
  if (checkForDefaultLocale(locale!)) {
    return {
      props: {
        defaultLocale: true,
      },
    };
  }

  return {
    props: {
      defaultLocale: false,
    },
  };
};
